import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChartConfig } from 'src/app/model/kpi.interface';

@Component({
  selector: 'app-donut',
  templateUrl: './donut.component.html',
  styleUrls: ['./donut.component.scss']
})
export class DonutComponent implements OnInit, OnDestroy {
  showTooltip: boolean;
  tooltipTop: number;
  tooltipLeft: number;
  legendColor: string;
  legendName: string;
  config: ChartConfig[];
  @Input() set setConfig(config: ChartConfig[]) {
    this.config = config;
    this.showCenterGraphText();
  }
  @Input() title: string;
  @Input() legend = true;
  @Input() textCenter = false;
  @Input() test = true;
  @Input() width = 300;
  @Input() height = 300;
  mouseMoveObs$: Observable<any>;
  mouseMoveSubscription: Subscription;
  centerGraphText: string;
  private _ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.mouseMoveObs$ = fromEvent(document, 'mousemove');
  }

  mouseEnter(color: string, tooltipContent: string): void {
    this.showTooltip = true;
    this.mouseMoveSubscription = this.mouseMoveObs$.pipe(
      takeUntil(this._ngUnsubscribe),
    ).subscribe((ev: MouseEvent) => {
      this.tooltipTop = ev.clientY - 20;
      this.tooltipLeft = ev.clientX - (23 + 3 * tooltipContent.length);
      this.cdr.detectChanges();
    });
    this.legendColor = color;
    this.legendName = tooltipContent;
  }

  mouseLeave(): void {
    this.showTooltip = false;
    this.mouseMoveSubscription.unsubscribe();
  }

  /* If selected job doesn't have video module, we are receiving 100 for video conversion,
  and after multiplying with 100 to get % in KPI class we get 10000.
  In that case we have to display message instead of graph*/

  showCenterGraphText(): void {
    this.centerGraphText =
      this.config[0].percentage === 10000
        ? 'Video module not attached to job'
        : `${Math.round(this.config[0].percentage)}%`;
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }
}
