<div class="chart-container">
  <div class="chart-wrapper" [ngClass]="{'test': !test}">
    <header *ngIf="test">
      <h3 class="chart-title">{{title}}</h3>
    </header>
    <div class="donut-graph">
      <div class="svg-container">
        <div class="mouse-position-tooltip"
             *ngIf="showTooltip"
             [ngStyle]="{'top.px': tooltipTop, 'left.px': tooltipLeft}">
          <div class="tooltip-square"
               [ngStyle]="{'backgroundColor': legendColor }"></div>
          <p class="tooltip-text">{{legendName}}</p>
        </div>
        <svg [attr.width]="width"
        [attr.height]="height"
             viewBox="0 0 42 42"
             class="chart">
          <circle *ngIf="config[0].percentage !== 10000"
                  cx="21"
                  cy="21"
                  r="15.91549430918954"
                  stroke-width="2"
                  stroke="#e5e5e5"
                  stroke-linecap="round"
                  fill="none" />
          <circle *ngIf="config[0].percentage !== 10000"
                  [style.stroke-dasharray]="config[0].percentage + ' ' + (100 - config[0].percentage)"
                  stroke-dashoffset="25"
                  cx="21"
                  cy="21"
                  r="15.91549430918954"
                  stroke-width="2"
                  [style.stroke]="config[0].color"
                  stroke-linecap="round"
                  fill="none"
                  (mouseenter)="mouseEnter(config[0].color, config[0].name)"
                  (mouseleave)="mouseLeave()" />
          <circle *ngIf="config[1]" cx="21"
                  cy="21"
                  r="11"
                  stroke-width="2"
                  stroke="#e5e5e5"
                  stroke-linecap="round"
                  fill="none" />
          <circle *ngIf="config[1]" [style.stroke-dasharray]="0.6911503837897544*config[1].percentage + ' ' + (69.11503837897544 - 0.6911503837897544*config[1].percentage)"
                  stroke-dashoffset="17.27875959474386"
                  cx="21"
                  cy="21"
                  r="11"
                  stroke-width="2"
                  [style.stroke]="config[1].color"
                  stroke="#00c3a1"
                  stroke-linecap="round"
                  fill="none"
                  (mouseenter)="mouseEnter(config[1].color, config[1].name)"
                  (mouseleave)="mouseLeave()" />
          <circle *ngIf="config[2]"
                  cx="21"
                  cy="21"
                  r="6"
                  stroke-width="2"
                  stroke="#e5e5e5"
                  stroke-linecap="round"
                  fill="none"/>
          <circle *ngIf="config[2]"
                  [style.stroke-dasharray]="0.3769911184308*config[2].percentage + ' ' + (37.69911184308 - 0.3769911184308*config[2].percentage)"
                  stroke-dashoffset="9.42477796077"
                  cx="21"
                  cy="21"
                  r="6"
                  stroke-width="2"
                  [style.stroke]="config[2].color"
                  stroke="#00c3a1"
                  stroke-linecap="round"
                  fill="none"
                  (mouseenter)="mouseEnter(config[2].color, config[2].name)"
                  (mouseleave)="mouseLeave()" />
        </svg>
        <div *ngIf="textCenter" [ngClass]="{'small': this.config[0].percentage === 10000}" class="graphText">{{centerGraphText}}</div>
      </div>
    </div>
    <app-chart-legend *ngIf="legend" [config]="config"></app-chart-legend>
  </div>
</div>
