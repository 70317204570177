import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollToInvalidFieldsService {

  private _invalidFields: Set<number> = new Set<number>();
  private _scrollToInvalidField$: Subject<number> = new Subject<number>();

  constructor() { }

  get scrollToInvalidField$(): Observable<number> {
    return this._scrollToInvalidField$.asObservable();
  }

  scroll(): void {
    if (this._invalidFields.size) {
      for (const element of this._invalidFields) {
        this._scrollToInvalidField$.next(element);
        break;
      }
    }
  }

  addInvalidField(fieldSignature: number): void {
    this._invalidFields.add(fieldSignature);
  }

  removeInvalidField(fieldSignature: number): void {
    this._invalidFields.delete(fieldSignature);
  }

  clear(): void {
    this._invalidFields.clear();
  }
}
