<div class="carousel-wrapper"
     (mouseenter)="pause()"
     (mouseleave)="onMouseLeave()">
  <ol class="carousel-indicators">
    <li class="ng-star-inserted"
        *ngFor="let image of images; let i= index"
        [ngClass]="{'active': i === currentSlide}"
        (click)="onInidicatorClicked(i)"></li>
  </ol>
  <div class="carousel">
    <div class="carousel-inner">
      <div *ngFor="let image of images; let i= index">
        <div class="carousel__photo"
             [ngStyle]="{'background-image': 'url(' + image + ')'}"
             [ngClass]="{'active': i === currentSlide, 'next': i === nextSlide, 'prev': i === prevSlide}">
        </div>
      </div>
    </div>
  </div>
</div>
