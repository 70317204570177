import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'requisitionStatusName'
})
export class RequisitionStatusNamePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
  ) { }

  transform(status: string): string {
    return this.translateService.instant('REQUISITION_STATUS.' + status.split(/(?=[A-Z])/).join('_').toLocaleUpperCase());
  }
}
