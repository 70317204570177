<div [ngClass]="{'chart-container': standAlone}">
  <div [ngClass]="{'chart-wrapper': standAlone}">
    <header *ngIf="title" class="chart-header">
      <h3 class="chart-title">{{ title }}</h3>
    </header>
    <div class="chart-legend">
      <div class="chart-legend-name-column">
        <div class="chart-legend-name-wrapper"
             *ngFor="let item of config"
             [ngClass]="{'total': item.total}">
          <div class="dot"
               [ngStyle]="{'background-color': item.color}"
               *ngIf="!item.total"></div>
          <div class="chart-legend-name">{{ item.name }}</div>
        </div>
      </div>
      <div class="chart-legend-quantity-wrapper">
        <div class="chart-legend-percentage-column">
          <div class="chart-legend-percentage"
               *ngFor="let item of config"
               [ngClass]="{'total': item.total}">{{ item.percentage }}%</div>
        </div>
        <div class="chart-legend-number-column">
          <div class="chart-legend-number"
               *ngFor="let item of config"
               [ngClass]="{'total': item.total}">{{ item.number || 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
