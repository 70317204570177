import {Directive, ElementRef, Output, Input, EventEmitter, HostListener} from '@angular/core';

@Directive({
  selector: '[appOutsideClick]'
})
export class OutsideClickDirective {

  private _initialized: boolean;

  constructor(
    private elementRef: ElementRef
  ) {}

  @Output() outsideClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() appOutsideClick: boolean;

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement): void {
    if (!targetElement) {
        return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    if (this.appOutsideClick) {
      this._initialized = true;
    }

    if (!clickedInside && this._initialized) {
        this.outsideClick.emit();
    }

    this._initialized = true;
  }
}
