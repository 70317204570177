<div class="drop-container" [class]="addClass">
  <div class="drop-content">
    <ngx-file-drop dropZoneLabel="Drop files here"
                   (onFileDrop)="onFilesAdded($event)"
                   [multiple]="true"
                   [accept]="allowedFileFormats.join(', ')"
                   [disabled]="(_alreadyUploadedFiles.length + filesToUpload.length) === maximumNumberOfFiles"
                   dropZoneClassName="ngx-file-drop-custom__drop-zone"
                   contentClassName="ngx-file-drop-custom__content">
      <ng-template ngx-file-drop-content-tmp
                   let-openFileSelector="openFileSelector">
        <div class="drop-zone-content">
          <i class="icon-cloud icon"></i>
          <div class="controls">
            <span class="message">
              {{ 'UPLOAD_RESUME.DRAG_AND_DROP_TEXT' | translate}}
            </span>
            <button type="button"
                    class="btn-browse-files"
                    [disabled]="(_alreadyUploadedFiles.length + filesToUpload.length) === maximumNumberOfFiles"
                    (click)="openFileSelector()">
              {{ 'UPLOAD_RESUME.BROWSE_INPUT' | translate}}
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>

  </div>
  <div class="files-wrapper" *ngIf="filesToUpload.length || _alreadyUploadedFiles.length">
    <div class="file" *ngFor="let file of _alreadyUploadedFiles; let i = index">
      <a class="file-name"
         *ngIf="file.file"
         [href]="file.file | domSanitizer: 'url'"
         target="_blank">
        {{ file.name }}
      </a>
      <span class="file-name" *ngIf="!file.file">
        {{ file.name }}
      </span>

      <i class="icon-cancel-in-circle remove-file-btn"
         (click)="removeAlreadyUploadedFile(i, file)"></i>
    </div>
    <div class="file" *ngFor="let file of filesToUpload; let i = index">
      <span class="file-name">{{file.fileName}}</span>
      <i class="icon-cancel-in-circle remove-file-btn"
         (click)="removeFileToUpload(i, file)"></i>
    </div>
  </div>
</div>
