import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { ALLOWED_DOCUMENT_EXTENSIONS, ALLOWED_DOCUMENT_FORMATS } from 'src/app/resources/allowed-file-formats';
import { FileUpload, IFile } from 'src/app/model/upload-files.model';

@Component({
  selector: 'app-attach-document',
  templateUrl: './attach-document.component.html',
  styleUrls: ['./attach-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachDocumentComponent {

  @Input() addClass: string;
  @Input() filesToUpload: FileUpload[] = [];

  _alreadyUploadedFiles: IFile[] = [];
  @Input() set alreadyUploadedFiles(files: IFile[]) {
    this.initializeAlreadyUploadedFiles(files);
  };

  @Input() allowedFileFormats: string[] = ALLOWED_DOCUMENT_FORMATS;
  @Input() allowedFileExtensions: string[] = ALLOWED_DOCUMENT_EXTENSIONS;
  @Input() maximumNumberOfFiles = 3;
  @Input() maxAllFilesSize = 26214400; // 25MB
  @Input() context: string;

  sizeCount = 0;

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) { }

  onFilesAdded(entries: NgxFileDropEntry[]): void {
    if (entries.length + this._alreadyUploadedFiles.length + this.filesToUpload.length > this.maximumNumberOfFiles) {
      this.toastr.error(
        this.translateService.instant(
          'SHARED.UPLOAD_MORE_THAN_PLURAL_FILES_NOT_ALLOWED',
          { number: this.maximumNumberOfFiles }
        )
      );
      return;
    }

    entries.forEach(({fileEntry}: NgxFileDropEntry) => {
      if (fileEntry.isDirectory || !fileEntry.isFile) {
        this.toastr.error(
          this.translateService.instant(
            'RECRUITMENT.FILENAME_IS_NOT_A_FILE',
            { filename: fileEntry.name }
          )
        );

        return;
      }

      (fileEntry as FileSystemFileEntry)
        .file((file: File) => {
          if (!this.allowedFileFormats.includes(file.type)) {
            const fileTypes = this.allowedFileExtensions.join(', ');
            this.toastr.error(
              this.translateService.instant('RECRUITMENT.DOCUMENT_TYPES_ALLOWED',{fileTypes}),
              this.translateService.instant('RECRUITMENT.DOCUMENT_TYPE_NOT_ALLOWED')
            );

            return;
          }


          if (this.sizeCount + file.size >= this.maxAllFilesSize) {
            this.toastr.error(
              this.translateService.instant(
                'RECRUITMENT.FILES_SIZE_ERR_MSG',
              )
            );

            return;
          }

          this.convertToBase64(file);
        });
    });
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const result = reader.result.toString();
      const base64 = result.split(',')[1];
      const sameFile = this.filesToUpload
        .find((fileChosen: FileUpload) => fileChosen.base64 === base64 && fileChosen.fileName === file.name);

      if (sameFile) {
        return;
      }

      const fileToUpload: FileUpload = {
        fileName: file.name,
        base64: result.split(',')[1],
        size: file.size
      };

      if (this.context) {
        fileToUpload.context = this.context;
      }

      this.filesToUpload.push(fileToUpload);

      this.sizeCount += file.size;

      this.cdr.detectChanges();
    };

    reader.onerror = () => {
      this.toastr.error(
        this.translateService.instant('RECRUITMENT.DOCUMENT_PROCESSING_ERR_MSG')
      );
    };
  }

  initializeAlreadyUploadedFiles(files: IFile[]): void {
    this.sizeCount = 0;
    this._alreadyUploadedFiles = files;
    files.forEach((file: IFile) => {
      // in SRI attachments we don't have this information
      if (file.size) {
        this.sizeCount += file.size;
      }
    });
    this.cdr.detectChanges();
  }

  removeFileToUpload(index: number, file: FileUpload): void {
    this.filesToUpload.splice(index, 1);
    this.sizeCount -= file.size;
    this.cdr.detectChanges();
  }

  removeAlreadyUploadedFile(index: number, file: IFile): void {
    this._alreadyUploadedFiles.splice(index, 1);
    this.sizeCount -= file.size;
    this.cdr.detectChanges();
  }
}
