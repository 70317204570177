import { Pipe, PipeTransform } from '@angular/core';
import { HiringStatus } from 'src/app/classes/hiring-status.class';
import { setHiringStatusName } from 'src/app/resources/shared-functions';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'statusName'
})
export class StatusNamePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) { }

  transform(status: HiringStatus): string {
    return setHiringStatusName(status, this.translateService);
  }
}
