import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormControlStatus } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ScrollToInvalidFieldsService } from 'src/app/services/scroll-to-invalid-fields.service';

@Directive({
  selector: '[scrollToInvalidField]'
})
export class ScrollToInvalidFieldDirective implements OnDestroy {

  controlToCheck: AbstractControl;
  @Input() set scrollToInvalidField(controlToCheck: AbstractControl) {
    this.controlToCheck = controlToCheck;
    this.initialize();
  }

  fieldSignature: number = this.generateRandomNumber();

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private scrollToInvalidFieldService: ScrollToInvalidFieldsService
  ) { }

  initialize(): void {
    this._ngUnsubscribe$.next();
    this.checkStatus(this.controlToCheck.status);
    this.trackControlStatusChanges();
    this.trackScrollToEvent();
  }

  checkStatus(status: FormControlStatus): void {
    if (status === 'INVALID') {
      this.scrollToInvalidFieldService.addInvalidField(this.fieldSignature);
    } else {
      this.scrollToInvalidFieldService.removeInvalidField(this.fieldSignature);
    }
  }

  trackControlStatusChanges(): void {
    this.controlToCheck.statusChanges
      .pipe(
        takeUntil(this._ngUnsubscribe$),
      )
      .subscribe((status: FormControlStatus) => {
        this.checkStatus(status);
      });
  }

  trackScrollToEvent(): void {
    this.scrollToInvalidFieldService.scrollToInvalidField$
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        filter((fieldSignature: number) => fieldSignature === this.fieldSignature)
      )
      .subscribe(() => this.scrollToElementView());
  }

  scrollToElementView(): void {
    setTimeout(() => {
      this.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 300);
  }

  generateRandomNumber(): number {
    return crypto.getRandomValues(new Uint16Array(1))[0];
    // return (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295).toString(36).substring(2, 15);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
