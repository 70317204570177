<div class="datepicker-wrapper"
     *ngIf="displayCalendar">
  <bs-datepicker-inline [bsValue]="bsInlineValue"
                        [bsConfig]="{ showWeekNumbers: false, customTodayClass: 'custom-today-class' }"
                        [dateCustomClasses]="dateCustomClasses"
                        [minDate]="minDate"
                        (bsValueChange)="onSelectedDateChange($event)">
  </bs-datepicker-inline>
</div>

<div class="event-list-wrapper" *ngIf="eventListForDate.length > 0">
  <div class="event-list-title" [ngClass]="{'is-event-picker' : isEventPicker}">
    {{ 'INTERVIEW.SCHEDULED_EVENTS' | translate }}
  </div>
  <div class="event-list">
    <div *ngFor="let event of eventListForDate" class="event" [ngClass]="{'is-event-picker' : isEventPicker}">
      <div class="event-time">
        <div class="time">
          {{ event.timeSlot.start | dateTime }} - {{ event.timeSlot.end | dateTime }}
        </div>
      </div>
      <a class="event-description"
         *ngIf="!isEventPicker && setEnabledLinkTime(event.timeSlot, event.status); else noLink"
         [href]="event.employeeLinkToMeeting"
         target="_blank">
         {{event.subject}} - {{'INTERVIEW.PARTICIPANTS' | translate}}: {{event.participants.join(', ')}}
      </a>
      <ng-template #noLink>
        <div class="event-description">
          {{event.subject}} - {{'INTERVIEW.PARTICIPANTS' | translate}}: {{event.participants.join(', ')}}
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="event-list-wrapper" *ngIf="pendingEventListForDate.length > 0">
  <div class="event-list-title" [ngClass]="{'is-event-picker' : isEventPicker}">
    {{ 'INTERVIEW.PENDING_EVENTS' | translate }}
  </div>
  <div class="event-list">
    <div *ngFor="let event of pendingEventListForDate" class="event" [ngClass]="{'is-event-picker' : isEventPicker}">
        <div class="event-time">
          <div class="time">
            {{ event.timeSlot.start | dateTime }} - {{ event.timeSlot.end | dateTime }}
          </div>
        </div>
        <div class="event-description">{{event.subject}} - {{'INTERVIEW.PARTICIPANTS' | translate}}: {{event.participants.join(', ')}}</div>
    </div>
  </div>
</div>
