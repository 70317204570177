import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { addMinutes } from 'src/app/resources/shared-functions';
import { InterviewEvent, TimeSlot } from 'src/app/model/interview.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UniversalValue } from 'src/app/model/universal-option.interface';
import { Observable, Subject, catchError, shareReplay, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { InterviewForCalendar } from 'src/app/classes/interviewForCalendar.class';
import { UniversalModalData } from 'src/app/model/modal.interface';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalService } from 'src/app/services/modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { InterviewService } from 'src/app/services/interview.service';
import { Modal } from 'src/app/classes/modal.class';
import { UniversalModalComponent } from '../../modal/universal-modal/universal-modal.component';
import { ICandidateApplication } from 'src/app/model/application.interface';
import { UserService } from 'src/app/services/user.service';
import { containsLinkValidation } from 'src/app/validators/contains-link.validator';
import { FileUpload, IFile } from 'src/app/model/upload-files.model';
import { GeneralTemplate } from 'src/app/model/templates.interface';
import { TemplatesService } from 'src/app/services/templates.service';
import { expandAnimation } from '../animations/expand.animation';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
  animations: [expandAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEventComponent implements OnInit, OnDestroy {
  @Input() timeSlots: TimeSlot[];
  @Input() openInModal: boolean;
  @Input() set interviewSubject(subject: string) {
    this.interviewForm.patchValue({
      subject: subject
    });
  }
  @Input() isGroup: boolean;
  @Input() candidateName: string;
  @Input() applicationId: number;
  @Input() set selectedApplications(value: ICandidateApplication[]) {
    this.applicationIds = value.map((app) => app['id']);
  }
  @Input() jobId: number;

  @Output() sendSuggestedEvent: EventEmitter<InterviewEvent> = new EventEmitter<InterviewEvent>();

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  eventListForDate: InterviewForCalendar[] = [];
  pendingEventlistForDate: InterviewForCalendar[] = [];

  minDate: Date;
  durations = [15, 30, 45, 60];
  selectedDuration: number;
  availableTimes: Date[] = [];
  hourArray: UniversalValue[] = [];
  minutesArray: UniversalValue[] = [];
  applicationIds: number[];
  readonly TEMPLATE_GREETING_MIN_LIMIT = 2;
  readonly TEMPLATE_GREETING_MAX_LIMIT = 50;
  filesToUpload: FileUpload[] = [];
  templateFiles: IFile[] = [];
  _candidateEmailExpand: boolean;
  templatesListCollapsed = true;

  workHoursStart: FormGroup = this.fb.group({
    hour: [9, Validators.required],
    minute: [0, Validators.required]
  });
  workHoursEnd: FormGroup = this.fb.group({
    hour: [17, Validators.required],
    minute: [0, Validators.required]
  });

  lunchBreakStart: FormGroup = this.fb.group({
    hour: [13, Validators.required],
    minute: [0, Validators.required]
  });
  lunchBreakEnd: FormGroup = this.fb.group({
    hour: [14, Validators.required],
    minute: [0, Validators.required]
  });

  selectedDate: Date = new Date();
  indexOfSlotReadyToConfirm: number;
  emailTemplates$: Observable<GeneralTemplate[]>;

  interviewForm: FormGroup = this.fb.group({
    greeting: [null, [
      Validators.maxLength(this.TEMPLATE_GREETING_MAX_LIMIT),
      Validators.minLength(this.TEMPLATE_GREETING_MIN_LIMIT)
    ]],
    timeSlots: [[]],
    subject: ['', [Validators.required]],
    introForEmailInvitation: [ '', [Validators.required, containsLinkValidation]],
    introForEmailInvitationText: [{value: '', disable: true}, [Validators.minLength(5), Validators.maxLength(6000)]]
  });

  get subject(): FormControl {
    return this.interviewForm.get('subject') as FormControl;
  }

  get introForEmailInvitation(): FormControl {
    return this.interviewForm.get('introForEmailInvitation') as FormControl;
  }

  get introForEmailInvitationText(): FormControl {
    return this.interviewForm.get('introForEmailInvitationText') as FormControl;
  }

  get time(): FormControl {
    return this.interviewForm.get('timeSlots') as FormControl;
  }

  get emailGreeting(): FormControl {
    return this.interviewForm.get('emailGreeting') as FormControl;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private errorHandlingService: ErrorHandlingService,
    private interviewService: InterviewService,
    private userService: UserService,
    protected translateService: TranslateService,
    private templatesService: TemplatesService
  ) {}

  ngOnInit(): void {
    this.populateArrays();
    const introMessage = this.isGroup
                          ? this.translateService.instant('INTERVIEW.INTRO_FOR_EMAIL_INVITATION_CONTENT_GROUP')
                          : this.candidateName
                            ? this.translateService.instant(
                                'INTERVIEW.INTRO_FOR_EMAIL_INVITATION_WITH_NAME_CONTENT',
                                {candidateName: this.candidateName})
                            : this.translateService.instant('INTERVIEW.INTRO_FOR_EMAIL_INVITATION_WITH_NAME_CONTENT');



    const signature = this.userService.user?.emailSignature;
    if (signature) {
      this.introForEmailInvitation
        .setValue('<p style="margin: 0; padding: 0;  font-size: 13px; line-height: 1.4;">'
                  + introMessage + '</p><br/>{{ linkToInterview }}' + '<br/><br/>' + signature);
    } else {
      this.introForEmailInvitation
        .setValue('<p style="margin: 0; padding: 0;  font-size: 13px; line-height: 1.4;">'
                  + introMessage + '</p><br/>{{ linkToInterview }}');
    }

    this.selectedDuration = this.timeSlots[0] ? this.getDuration(this.timeSlots[0]) : this.durations[0];
    this.minDate = new Date();
    this.time.setValue(this.timeSlots);

    this.subscribeToChange(this.workHoursStart);
    this.subscribeToChange(this.workHoursEnd);
    this.subscribeToChange(this.lunchBreakStart);
    this.subscribeToChange(this.lunchBreakEnd);

    this.emailTemplates$ = this.getEmailTemplates();
  }

  subscribeToChange(formGroup: FormGroup): void {
    formGroup.valueChanges
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe((value) => {
        if (value.hour === 24 && value.minute !== 0) {
          formGroup.controls.minute.setValue(0);
        }
        this.setAvailableTimes();
      });
  }

  populateArrays(): void {
    for (let i = 0; i <= 24; i++) {

      if (i <= 24) {
        const name = i < 10 ? `0${i}` : `${i}`;
        this.hourArray.push({name, value: i});
      }

      if (i < 4) {
        const name = i === 0 ? `0${i}` : `${i * 15}`;
        this.minutesArray.push({name, value: i * 15});
      }
    }
  }

  getDuration(timeSlot: TimeSlot): number {
    const result = (timeSlot.end.getHours()*60 + timeSlot.end.getMinutes()) -
                   (timeSlot.start.getHours()*60 + timeSlot.start.getMinutes());
    return result;
  }

  onSelectedDateChange(event: any): void {
    this.selectedDate = event.selectedDate;
    this.eventListForDate = event.eventListForDate;
    this.pendingEventlistForDate = event.pendingEventListForDate || [];
    this.setAvailableTimes();
  }

  setDuration(duration: number): void {
    this.selectedDuration = duration;
    this.indexOfSlotReadyToConfirm = null;
    this.setAvailableTimes();
  }

  setAvailableTimes(): void {
    this.availableTimes = [];
    const isSelectedDateToday = new Date().setHours(0,0,0,0) === new Date(this.selectedDate).setHours(0,0,0,0);
    const workHoursStartMinutes = isSelectedDateToday ?
                                  this.getTodayStartMinutes() :
                                  this.workHoursStart.get('hour').value * 60 + this.workHoursStart.get('minute').value;
    const workHoursEndMinutes = this.workHoursEnd.get('hour').value * 60 + this.workHoursEnd.get('minute').value;
    const workHoursEndTime = new Date(new Date(new Date(this.selectedDate).setHours(0,0,0,0)).setMinutes(workHoursEndMinutes));
    const lunchBreakStartMinutes = this.lunchBreakStart.get('hour').value * 60 + this.lunchBreakStart.get('minute').value;
    const lunchBreakStartTime = new Date(new Date(this.selectedDate).setMinutes(lunchBreakStartMinutes));
    const lunchBreakEndMinutes = this.lunchBreakEnd.get('hour').value * 60 + this.lunchBreakEnd.get('minute').value;
    const lunchBreakEndTime = new Date(new Date(new Date(this.selectedDate).setHours(0,0,0,0)).setMinutes(lunchBreakEndMinutes));
    let start = new Date(new Date(new Date(this.selectedDate).setHours(0,0,0,0)).setMinutes(workHoursStartMinutes));
    let end = addMinutes(start, this.selectedDuration);
    while (end <= workHoursEndTime) {
      let isWithinEvent = false;

      // don't add to available timeSlots if there is already scheduled interview at the same time
      this.eventListForDate.forEach(event => {
        if (!(start >= event.timeSlot.end || end <= event.timeSlot.start)) {
          isWithinEvent = true;
        }
      });

      // don't add to available timeSlots if it is overlapping with lunch break
      if (!(start >= lunchBreakEndTime || end <= lunchBreakStartTime)) {
        isWithinEvent = true;
      }

      // don't add to available timeSlots if there is already pending interview with the same suggested time
      this.pendingEventlistForDate?.forEach(event => {
        if (!(start >= event.timeSlot.end|| end <= event.timeSlot.start)) {
          isWithinEvent = true;
        }
      });

      // don't add to available timeSlots if it is already added to suggested timeSlots
      this.time.value.forEach(timeSlot => {
        if (!(start >= timeSlot.end || end <= timeSlot.start)) {
          isWithinEvent = true;
        }
      });
      if (!isWithinEvent) {
        this.availableTimes.push(new Date(start.getTime()));
      }
      start = end;
      end = addMinutes(start, this.selectedDuration);
    }
    this.cdr.detectChanges();
  }

  getTodayStartMinutes(): number {
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();
    let minutesToAdd = 0;

    this.durations.forEach(duration => {
      if (nowMinutes < duration && minutesToAdd === 0) {
        minutesToAdd = duration;
      }
    });

    return Math.max(
      (nowHours * 60 + minutesToAdd),
      (this.workHoursStart.get('hour').value * 60 + this.workHoursStart.get('minute').value));
  }

  timeItemClicked(index: number): void {
    this.indexOfSlotReadyToConfirm = this.indexOfSlotReadyToConfirm === index ? null : index;
  }

  confirmButtonClicked(time: Date): void {
    this.indexOfSlotReadyToConfirm = null;
    const addedTimeSlot = {
      start: time,
      end: addMinutes(time, this.selectedDuration),
    };
    if (this.isGroup) {
      this.time.setValue([addedTimeSlot]);
    } else {
      const sortedTimes = [...this.time.value, addedTimeSlot].sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0));
      this.time.setValue(sortedTimes);
    }

    this.setAvailableTimes();
  }

  sendEvent(): void {
    this.interviewForm.markAllAsTouched();
    if (!this.interviewForm.valid) {
      return;
    }

    const payload: InterviewEvent = {
      interviewContent: this.interviewForm.value,
      files: this.filesToUpload,
      templateFiles: this.templateFiles
    };

    this.sendSuggestedEvent.emit(payload);
  }

  cancel(): void {
    this.sendSuggestedEvent.emit(null);
  }

  removeSuggestedEvent(index: number): void {
    this.time.value.splice(index, 1);
    this.setAvailableTimes();
  }

  onSelectAll(): void {
    this.indexOfSlotReadyToConfirm = null;
    this.availableTimes.forEach(time => {
      const addedTimeSlot = {
        start: time,
        end: addMinutes(time, this.selectedDuration),
      };
      this.time.setValue([...this.time.value, addedTimeSlot]);
    });

    this.setAvailableTimes();
  }

  previewEmail(): void {
    this.interviewForm.markAllAsTouched();
    this.interviewForm.updateValueAndValidity();

    const { value, invalid } = this.interviewForm;

    if (invalid) {
      return;
    }

    this.loaderService.show();
    const id = this.isGroup ? this.jobId : this.applicationId || this.applicationIds;
    this.interviewService.getInterviewEmailPreview(value, this.isGroup, id.toString())
    .pipe(
      catchError((errorResponse: HttpErrorResponse) =>
        this.errorHandlingService.handleBackendError(errorResponse)
      )
    )
    .subscribe((content: string) => {
      const data: UniversalModalData = {
        title: this.translateService.instant('BUTTONS.EMAIL_PREVIEW'),
        htmlContent: content,
        disableHtmlContent: true,
        hideCancelButton: true,
        hideConfirmButton: true,
        isEmailPreview: true,
      };
      this.modalService.addModal(new Modal(UniversalModalComponent, data));
      this.loaderService.hide();
    });
  }

  getEmailTemplates(): Observable<GeneralTemplate[]> {
    return this.templatesService.getEmailTemplates()
      .pipe(
        shareReplay({bufferSize: 1, refCount: true})
      );
  }

  setEmailTemplate(templateId: number): void {
    this.loaderService.show();

    this.templatesService.getEmailTemplate(templateId)
      .subscribe((template: GeneralTemplate) => {

        this.interviewForm.patchValue(template);
        const signature = this.userService.user?.emailSignature;
        if (signature) {
          this.introForEmailInvitation
            .setValue(template.content + '<br/>{{ linkToInterview }}' + '<br/><br/>' + signature);
        } else {
          this.introForEmailInvitation
            .setValue(template.content + '<br/>{{ linkToInterview }}');
        }
        this.cdr.detectChanges();
        this.templateFiles = template.files;
        this.filesToUpload = [];
        this.cdr.detectChanges();
        this.loaderService.hide();
      });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
