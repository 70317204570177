import { IGroupInterview, IInterview, InterviewStatus, TimeSlot } from '../model/interview.interface';

export class InterviewForCalendar {

  status: InterviewStatus;
  subject: string;
  employeeLinkToMeeting: string;
  timeSlot: TimeSlot;
  timeSlots: TimeSlot[];
  participants: string[];

  constructor(interview: IInterview | IGroupInterview, employeeLinkToMeeting?: string) {
    this.status = interview.status;
    this.subject = interview.subject;
    this.employeeLinkToMeeting = ('employeeLinkToMeeting' in interview) ? interview.employeeLinkToMeeting : employeeLinkToMeeting;
    this.timeSlot = interview.timeSlot;
    this.timeSlots = ('timeSlots' in interview) ? interview.timeSlots : [interview.timeSlot];

    if ('customersInGroupInterview' in interview) {
      const participants = [];
      interview.customersInGroupInterview.forEach(customer => participants.push(customer.employee.name));
      interview.enterpriseManagersInGroupInterview.forEach(enterpriseManager => participants.push(enterpriseManager.employee.name));
      interview.candidatesInGroupInterview.forEach(candidate => participants.push(candidate.candidate.email || candidate.candidate.email));
      interview.thirdPartiesInGroupInterview.forEach(party => participants.push(party.email));
      this.participants = participants;
    } else {
      this.participants = [interview.application?.candidateAppliedWithName];
    }
  }
}
