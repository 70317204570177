import { Directive, ElementRef, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';

@Directive({
  selector: '[autoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  @Input() autoFocus: boolean;

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    ) { }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.elementRef.nativeElement.focus();
      this.cdr.detectChanges();
    }
  }
}
