import { NgModule } from '@angular/core';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InitialsPipe } from './pipes/initials.pipe';
import { AutoFocusDirective } from './directives/autoFocus.directive';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AllowForRolesDirective } from './directives/allowForRoles.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { TrimValueAccessorDirective } from './directives/trim.directive';
import { QuillEditorComponent } from './quill-editor/quill-editor.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DonutComponent } from '../kpi-statistics/donut/donut.component';
import { ChartLegendComponent } from '../kpi-statistics/chart-legend/chart-legend.component';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';
import { CustomCarouselComponent } from './custom-carousel/custom-carousel.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AttachDocumentComponent } from './attach-document/attach-document.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { PasswordElementComponent } from './password-input/password-element/password-element.component';
import { PhoneEditComponent } from './phone-edit/phone-edit.component';
import { DomSanitizerPipe } from './pipes/dom-sanitizer.pipe';
import { StatusNamePipe } from './pipes/status-name.pipe';
import { StringDateToJsDatePipe } from './pipes/string-date-to-js-date.pipe';
import { ScrollToInvalidFieldDirective } from './directives/scroll-to-invalid-field.directive';
import { DateTimePipe } from './pipes/date-time.pipe';
import { DateDayMonthPipe } from './pipes/date-day-month.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CalendarComponent } from './calendar/calendar.component';
import { AddEventComponent } from './calendar/add-event.component';
import { RequisitionStatusNamePipe } from './pipes/requisition-notification.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    CollapseModule,
    ScrollingModule,
    NgxPaginationModule,
    EditorModule,
    NgxFileDropModule,
    BsDatepickerModule,
    PasswordInputComponent,
    PasswordElementComponent,
    FooterComponent,
    HeaderComponent,
    PhoneEditComponent,
    CustomCarouselComponent,
    ContactUsComponent,
    TooltipModule
  ],
  declarations: [
    InitialsPipe,
    AutoFocusDirective,
    AllowForRolesDirective,
    OutsideClickDirective,
    TrimValueAccessorDirective,
    CopyToClipboardDirective,
    QuillEditorComponent,
    DonutComponent,
    ChartLegendComponent,
    AttachDocumentComponent,
    DomSanitizerPipe,
    DateTimePipe,
    DateDayMonthPipe,
    CalendarComponent,
    AddEventComponent,
    StatusNamePipe,
    StringDateToJsDatePipe,
    ScrollToInvalidFieldDirective,
    RequisitionStatusNamePipe
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    TranslateModule,
    InitialsPipe,
    AutoFocusDirective,
    CollapseModule,
    AllowForRolesDirective,
    ScrollingModule,
    OutsideClickDirective,
    TrimValueAccessorDirective,
    CopyToClipboardDirective,
    QuillEditorComponent,
    NgxPaginationModule,
    DonutComponent,
    ChartLegendComponent,
    CustomCarouselComponent,
    EditorModule,
    NgxFileDropModule,
    AttachDocumentComponent,
    PasswordInputComponent,
    PasswordElementComponent,
    PhoneEditComponent,
    DateTimePipe,
    DateDayMonthPipe,
    CalendarComponent,
    AddEventComponent,
    DomSanitizerPipe,
    StatusNamePipe,
    StringDateToJsDatePipe,
    ScrollToInvalidFieldDirective,
    RequisitionStatusNamePipe
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
})
export class SharedModule {}
